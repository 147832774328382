.chatGPT {
    font-size: 15px;
    top: env(safe-area-inset-top);
    left: calc(env(safe-area-inset-left) + 5px);
    width: calc(100% - env(safe-area-inset-left) - 10px);
    max-width: calc(100% - env(safe-area-inset-left) - 10px);
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 5px;
}

.benxtAskAppOnly .keyboardHeaderButtonCancel {
    visibility: hidden;
}

.benxtAskAppOnly .keyboardHeader .inputControlKeyboardButton1 {

}

.chatGPTKeyboard {
    z-index: 100;
    position: absolute;
    width: 100%;
    top: 40px;
    height: 100px;
}

.chatGPTKeyboard .keyboardHeader {
    display: none;
}

.chatGPTKeyboard .keyboardWindow {
    overflow-y: visible;
    min-height: 160px;
}

.chatGPTKeyboard .keyboardInputContainer {
    display: none;
}

.chatGPTKeyboard .keyboardInstructionListContainer {
    background: rgb(0, 26, 25);
}

.benxtAppDesktop .chatGPT {
    font-size: 14px;
}

.chatGPT .inputControlTopRow {

}

.chatGPTInput {
    position: absolute;
    bottom: 5px;
    width: 100%;
}

.chatGPTInputWithMenu .inputControlInputEditor {
    width: calc(100% - 40px);
}

.chatGPT .uiChatMarginBottom {
    display: flex;
    width: 100%;
    min-width: 100%;
    margin-top: 0px;
    height: calc(100% - (132px - 42px)); 
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.chatGPT .uiChatMarginBottomSearchField {
    margin-top: 42px;
    height: calc(100% - (132px - 0px)); 
}

.chatGPT .chatMessagesSliderEnabled .uiChatMarginBottom {
    width: 50%;
    min-width: 50%;
}

.chatGptChatMessageHeaderTopic {
    color: #888888;
}

.chatGPT .uiChatMarginBottomThread {
    justify-content: space-between;
}

.chatGPT .keyboardEditDocument {
    margin-top: 0;
}

.chatGPT .keyboardHeader {
    margin-bottom: 5px;
}

.chatGPT .uiChatMessages {
    padding-top: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
}

.chatGPT .uiChatMessagesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 6px;
}

.chatGPT .keyboardEditInstruction {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
}


.chatGPTMessageReactions {
    width: 100%;
    transform: translate(40px, 0);
    letter-spacing: 4px;
    padding-bottom: 2px;
}

.chatGptSearchFieldContainer {
    background: rgb(0, 26, 25);
    top: 40px;
    padding-top: 6px;
    position: absolute;
    width: 100%;
    height: 42px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chatGptSearchFieldContainer .inputControlMiddleRowContainer {
    position: relative;
    flex-grow: 0;
    display: flex;
    width: auto;
}

.chatGPTInput .inputControlMiddleRowContainer {
    position: relative;
    flex-grow: 0;
    display: flex;
    width: auto;
}

.chatGptSearchFieldContainer .keyboardAddButton {
    position: relative;
}

.chatGptQuestions .keyboardAddButton {
    position: relative;
}

.chatGptSearchFieldContainer .keyboardInstructionInput {
    min-height: auto;
    height: 40px;
}

.chatGPT .keyboardMenuAutocompletes {
    position: absolute;
    top: 81px;
    right: 0;
    left: auto;
    bottom: auto;
}

.chatGPT .chatGPTQuestionsAutocomplete {
    width: calc(100vw - 10px);
    justify-content: flex-end;
}

.benxtAppDesktop .chatGPTQuestionsAutocomplete {
    width: calc(600px - 10px);
}

.chatGPT .keyboardMenuAutocompletes {
    row-gap: 1px;
}

.chatGPT .keyboardMenuAutocompletes.chatGPTQuestionsAutocomplete .keyboardMenuItemAutocomplete {
    background: rgb(0, 26, 25);
}

.chatGPT .keyboardInstructionInput .inputControlBottomRow {
    display: none;
}

.chatGPT .keyboardMenuAutocompletes .keyboardMenuItem {
    background: rgb(49, 47, 102);
}

.chatGptThreads {
    width: 100%;
    position: relative;
    z-index: 10;
    top: 0;
    max-width: 40px;
}

.chatGptQuestions {
    width: 100%;
    position: relative;
    z-index: 10;
    top: 0;
}

.chatGptThreadsMenu {
    background: rgb(0, 26, 25);
    position: absolute;
    top: calc(80px + env(safe-area-inset-top) + 6px);
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    overflow-y: auto;
    max-height: calc(100% - 80px - env(safe-area-inset-top) - 10px);
    width: calc(100% - 10px);
    left: 5px;
    z-index: 20;
}

.benxtAppSafari .chatGptThreadsMenu {
    position: fixed;
}

.benxtAppSafari .chatGptQuestionsMenu {
    position: fixed;
}


.chatGptQuestionsMenu {
    background: rgb(0, 26, 25);
    position: absolute;
    bottom: calc(80px + env(safe-area-inset-bottom) + 6px);
    padding-bottom: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    overflow-y: auto;
    max-height: calc(100% - 80px - env(safe-area-inset-bottom) - 10px);
    width: calc(100% - 10px);
    left: 5px;
    z-index: 20;
}


.benxtAppDesktop .chatGptThreadsMenu {
    left: calc((100vw - 600px) / 2 + 5px);
    width: calc(600px - 10px);
}

.benxtAppDesktop .chatGptQuestionsMenu {
    left: calc((100vw - 600px) / 2 + 5px);
    width: calc(600px - 10px);
}

.chatGptChatMessageHeader {
    color: #c7c7c7;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 15px;
    padding-bottom: 5px;
}

.benxtAppDesktop .chatGptChatMessageHeader {
    font-size: 14px;
}

.chatGptChatMessageHeader.chatMessageFromUser {
    background: rgb(0, 26, 25);
}

.chatGptChatMessageHeader.chatMessageFromGpt {
    background: rgb(2, 77, 87);
}

.chatGptChatMessageHeader.chatMessageFromGpt {
    background: rgb(2, 77, 87);
}

.chatGptChatMessageTimestamp {
    font-size: 15px;
    white-space: nowrap;
    padding-left: 10px;
    color: #888888;
}

.chatGPT .keyboardAddButton {

}

.benxtAppSafari .chatGPT .keyboardAddButton {
    box-sizing: border-box;
}


.benxtAppDesktop .chatGptChatMessageTimestamp {
    font-size: 14px;
}


.chatMessagesSlider {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    min-width: 100%;
    overflow-x: hidden;
}

.chatMessagesSliderEnabled {
    overflow-x: auto;
    min-width: 200%;
}

.chatMessagesSliderContainer {
    display: flex;
    width: 100%;
    height: calc(100% - 84px);
    overflow-y: auto
}

.chatMessagesBack {
    position: absolute;
    top: 0px;
}

.benxtAppDesktop .chatMessagesBack.keyboardMenuItem {
    font-size: 14px;
}

.chatMessagesBackBusy .keyboardMenuItemIconRight {
    position: absolute;
    right: 0;
}

.chatGptThreadMenuThreads {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
}

.chatGptQuestionMenuQuestions {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
}

.chatGptQuestionMenuQuestions .keyboardMenuItem {
    font-size: 15px;
}

.benxtAppDesktop .chatGptQuestionMenuQuestions .keyboardMenuItem {
    font-size: 15px;
}
.chatGptThreadMenuThreads .keyboardMenuItem {
    font-size: 15px;
}

.benxtAppDesktop .chatGptThreadMenuThreads .keyboardMenuItem {
    font-size: 14px;
}

.copyAISaid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
}

.retryAISaid .uiOKCancelOK {
    background: #63130a !important;
}

.aiCheckButton svg path {
    fill: #e7e7e7 !important;
}

.chatGptChatMessage {
    width: 100%;
}

.chatGptChatMessage .swiper {
    width: 100%;
}

.chatGptChatMessageBody {
    width: 100%;
}

.copyAISaid .uiOKCancelCancel {
    background: transparent;
}

.keyboardEditDocumentError .copyAISaid {

}

.keyboardEditDocumentError .uiOKCancelOK {
    background: transparent;
}

.keyboardEditDocumentError .aiCommentError {
    margin-top: 6px !important;
    background: #63130a;
}

.chatGptChatMessageError .chatGptChatMessageHeader  {
    background: #63130a;
    display: none;
}

.chatGptChatMessageError .aiCheck {
    display: none;
}

.chatGptChatMessageError .keyboardEditDocument {
    background: #63130a;
}

.userSaidDel .uiOKCancelCancel {
    background: transparent;
}

.keyboardAddButton .uiOKCancelCancel {
    background: rgb(49, 47, 102);
}


.keyboardEditDocumentText p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.keyboardEditDocumentText ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 10px;
}


.aiComment {
    margin-top: 6px !important;
    background: #0f2733;
}

.aiHallucinated {
    margin-top: 6px !important;
    opacity: 0.7;
    background: rgb(49,0,0);
}

.aiTrace {
    margin-top: 0px !important;
    margin-bottom: 6px !important;
}

.aiReply {
    margin-top: 6px !important;
}

.aiComment .uiOKCancelCancel {
    opacity: 0.5;
    background: transparent;
}

.aiCheck {
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: middle;
    padding-left: 4px;
}

.aiCheck svg {
    display: inline-block;
    height: 20px;
    width: 20px;
}

.aiCheck div {
    display: inline-block;
}

.keyboardEditDocumentTextInline {
    width: 100%;
    display: inline-block;
}

.aiLinkWithTooltip {
    display: inline-block;
}

.aiLink {
    color: #6dad88;
    display: inline;
    cursor: pointer;
    white-space: normal;
}

.keyboardEditDocumentTextInline > span {
}

.keyboardEditDocumentTextInline table {
    border-spacing: 0;
    border-collapse: collapse;
    padding: 5px;
    display: inline-block;
}

.keyboardEditDocumentTextInline td,th {
    text-align: center;
    border-spacing: 0;
    border: 1px solid #e7e7e7;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: content-box;
}

.keyboardEditDocumentTextInline > div > pre {
    width: 100%;
}

.keyboardEditDocumentTextInline  pre {
    margin: 0 !important;
    padding: 10px !important;
    font-size: 15px !important;
    font-family: apparat;
}

.keyboardEditDocumentTextInline  .language-text {
    color: #1144cc !important;
}

.chatGptTooltip {
    position: absolute;
    left: 10px;
    bottom: 12px;
    color: #e7e7e7;
    font-size: 11px;
    opacity: .8;
    max-width: calc(100% - 10px - 120px)
}

.inputControlKeyboardButton.newTopicButton .uiOKCancelOK {
    background: rgb(49, 47, 102);
    width: 100px;
}

.aiCode {
    display: inline;
    color: #f0f0f0;
    font-weight: 500;
    font-family: apparat;
}

.chatGPT .inputControlSpectrumAnalyzer {
}

.chatGPT .inputControlSpectrumAnalyzer .micSpectrumAnalyzerContainer {
}

.modelSelection {
    width: 100%;
    position: absolute;
    top: 90px;
}



.keyboardRadioButton {
    width: 100%;
    display: flex;
    height: 40px;
    column-gap: 1px;
}

.keyboardRadioButtonIcon {
    display: flex;
    align-items: center;
    
}

.keyboardRadioButtonLabel {
    height: 40px;
    color: #e7e7e7;
    background: rgb(7, 100, 97);
    display: flex;
    align-items: center;
    max-width: 80px;
    justify-content: center;
}

.keyboardRadioButtonLeft {
    height: 40px;
    min-width: 40px;
    background: rgb(1, 154, 158);
}

.keyboardRadioButtonRight {
    height: 40px;
    width: 40px;
    background: rgb(7, 100, 97);
}

.keyboardRadioButtonIcon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardRadioButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardRadioButtonIcon svg {
    height: 20px;
    width: 20px;
}

.keyboardRadioButtonOn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    color: #e7e7e7;
    cursor: pointer;
    width: 80px;
}

.keyboardRadioButtonOff {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #e7e7e7;
    height: 40px;
    width: 80px;
}

.keyboardRadioButtonUnselected {
    display: flex;
    column-gap: 1px;
    background: rgb(51, 51, 51);
}

.keyboardRadioButtonSelected {
    background: rgb(59, 101, 201);
    display: flex;
    column-gap: 1px;
}

.keyboardRadioButtonOffButton {
    height: 40px;
    width: 40px;
    background: rgb(99, 19, 9);
}

.benxtAppMobile .keyboardRadioButtonLabel {
}

.benxtAppMobile .keyboardRadioButtonRight {
    display: none;
}

.benxtAppMobile .keyboardRadioButtonOn {
    display: none;
}

.benxtAppMobile .keyboardRadioButtonOff {
    display: none;
}


.chatGPTModelHeader {
    display: flex;
    align-items: center;
    font-size: 14px;
}
