.bnClient {
    height: 100%;
    width: 100%;
    background: rgb(0, 26, 25);
}

benxtAppDesktop .bnClient {
    height: auto;
    overflow-y: auto;
}

.benxtAppWindows::scrollbar {
    display: none;
}
