.bnClient.benxtAppDesktop {
    display: flex;
    width: 100%;
    justify-content: center;
    background: #002C2B;
    height: 100%;
    overflow: hidden;
}

.benxtAppDesktop .keyboardSplash {
    position: absolute;
    font-family: Apparat;
}


/*
.benxtAppDesktop  .bnPageBody {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}
.benxtAppDesktop  .keyboardHome {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}

.benxtAppDesktop .keyboardLogin {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}

.benxtAppDesktop .keyboardWordPackContent {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}
*/

.bnAppContent {
    height: 100%;
    width: 100%;
}

.benxtAppDesktop .bnAppContent {
    width: 100%;
    height: 100%;
    padding: 1px;
    overflow-x: hidden;
    width: 600px;
}

.benxtAppDesktop .keyboardApp {
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    position: relative;
}

.benxtAppDesktop .keyboardAppContent {
}


.keyboardApp {
    position: absolute;
    height: 100%;
    width: calc(100% - env(safe-area-inset-left));
}

.keyboardAppContent {
    height: 100%;
    width: 100%;
}

.bnPhoneSignIn .bnForm {
    padding-top: 0;
}


#recaptcha-fun {
    display: none;
}

iframe {
    top: env(safe-area-inset-top);
}

.bnSignInChoice {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: rgb(245, 90, 0);
    color: white;
    height: 30px;
    align-items: center;
    margin-bottom: 5px;
    display: none;
}


.bbMobileMain {
    height: 100%;
    width: 100%;
}

.bbMobileMainContent {
    height: calc(100% - 70px - env(safe-area-inset-bottom));
    min-height: calc(100% - 70px - env(safe-area-inset-bottom));
    width: 100%;
    overflow: hidden;
}

.bbMobileMainInputFocused .bbMobileMainContent {
    height: 100%;
    min-height: 100%;
}

.bbMobileMenu {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    height: 55px;
    background: black;
}

.bbMobileMenuTab {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    width: 100%;
    background: black;
    cursor: pointer;
}

.bbMobileMenuTabSelected {
    background: rgb(225, 80, 0);
    background: blue;
}

.bbMobileMenuTabLabel {
    color: white;
    padding-bottom: 5px;
    font-size: 11px;
}

.bbMobileMenuTabIcon {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.bbMobileMenuTabIcon div {
    display: flex;
   justify-content: center;
    align-items: center;
}

.bbMobileMenuTabIcon svg {
    height: 35px;
    width: 35px;
    fill: white;
}

.bballPlus {
    height: 55px;
    width: 55px;
    visibility: visible;
    border-radius: 45px;
}

.bballPlus svg {
    height: 45px;
    width: 45px;
    border-radius: 45px;
    border: 1.5px solid white;
    stroke: white;
}

.bballPlus:hover {
    background: blue;
}

.bballPlus:hover svg {
}

.profileSVG svg {
    stroke: white;
    height: calc(1.2 * 35px);
    width: calc(1.2 * 35px);
    stroke-width: 0.5px;
}

.bballSVG svg {
    height: calc(0.67 * 35px);
    width: calc(0.67 * 35px);
    stroke: white;
    stroke-width: 2.0px;
}

.benxtAppMobile .bbAppTitleLogo {
    margin-top: env(safe-area-inset-top);
}


.bbPeopleList {
    width: calc(100% - 30px);
    padding: 15px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background: rgb(61, 61, 61);
}


.bbUser {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    height: 35px;
    padding: 5px;
    justify-content: space-between;
}

.bbUserHeader {
    display: flex;
    align-items: center;
}

	 
.bbUserProfileIcon {
    height: 45px;
    width: 45px;
    border-radius: 45px;
    background: gray;
    margin-right: 10px;
}

.bbUserFriend .bnButton {
    background: blue;
}

.bbUserFollower .bnButton {
    background: white;
    color: black;
}

.bbUserFollower .bnButtonIcon svg circle {
    fill: rgb(74, 74, 74) !important;
}

.bbUserFollowing .bnButton {
    background: white;
    color: black;
}

.bbUserFollowing .bnButtonIcon svg g path {
    fill: black !important;
}


.keyboardSignIn {
    margin-top: calc(60px + 30px);
}

.keyboardVerificationCode {
    margin-top: calc(60px)
}

.keyboardSplashLogo img {
    height: 60px;
    width: 60px;
}

.keyboardSplash {
    position: fixed;
    background: rgb(4, 28, 35);
    color: #e7e7e7;
    font-family: -apple-system, apparat;
    font-weight: 500;
    font-size: 16px;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 99;
}

.keyboardSplashContent {
    position: absolute;
    top: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.keyboardLoginInProgressPage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
}
